import { useAutoAnimate } from '@formkit/auto-animate/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBestSellingProducts, getCategoryProducts, getDiscountProducts, getNewArrivalProducts } from '../../../features/productsSlice';
import ProductCard from '../../Shared/CommonCard/ProductCard';
import Pagination from '../../Shared/Pagination/Pagination';
import Sidebar from '../../Shared/Shop/Sidebar';

const Categories = () => {
    const { value } = useParams();
    const [totalData, setTotalData] = useState(null);
    const [animationParent] = useAutoAnimate();
    const cartData = useSelector((state) => state.cart);
    const favoriteData = useSelector((state) => state.favorite);
    const dispatch = useDispatch();
    const totalProducts = useSelector((state) => state.products);
    const allProducts = totalProducts.products?.data?.data;
    const newProducts = totalProducts?.newProducts?.data?.products;
    const discountProducts = totalProducts?.discountProducts?.data?.products;
    const bestSellingProducts = totalProducts?.bestSellingProducts?.data?.products;
    const categoryProducts = totalProducts?.categoryProducts?.data?.products;
    const [data, setData] = useState(allProducts || newProducts || discountProducts || bestSellingProducts || categoryProducts || []);    
    const [page, setPage] = useState(1);

    useEffect(() => {
      if (allProducts) {
        setData(allProducts);
      }
      else if (newProducts) {
        setData(newProducts?.data);
        setTotalData(newProducts)
      }
      else if (discountProducts) {
        setData(discountProducts?.data);
        setTotalData(discountProducts)
      }
      else if (bestSellingProducts) {
        setData(bestSellingProducts?.data);
        setTotalData(bestSellingProducts)
      }
      else{
        setData(categoryProducts?.data);
        setTotalData(categoryProducts)
      }
    }, [allProducts, newProducts, discountProducts, bestSellingProducts, categoryProducts]);

    const fetchDataBasedOnValue = (value) => {
      if (value === 'sales-products') {
        dispatch(getBestSellingProducts(page))
      } else if (value === 'discount-products') {
        dispatch(getDiscountProducts(page))
      } else if (value === 'new-arrivals') {
        dispatch(getNewArrivalProducts(page));
      } else {
        const data = {
          category: value,
          page: page
        }
        dispatch(getCategoryProducts(data))
      }
    };

    useEffect(() => {
      fetchDataBasedOnValue(value);
    }, [value, page, dispatch]);


    const [sortOption, setSortOption] = useState('');

    const handleSort = (event) => {
      const selectedSortOption = event.target.value;
      setSortOption(selectedSortOption);

      const sortedProducts = [...data].sort((a, b) => {
        if (selectedSortOption === 'name-asc') {
          return a.name.localeCompare(b.name);
        }else if(selectedSortOption === 'name-desc'){
          return b.name.localeCompare(a.name);
        }else if(selectedSortOption === 'price-asc'){
          return a.price - b.price;
        }else if(selectedSortOption === 'price-desc'){
          return b.price - a.price;
        }
        return 0;
      });

      setData(sortedProducts);
    };



  return (
        <section className="my-10 px-4 min-h-screen">
          <div className="flex gap-6">
            {/* sidebar-area */}
            <div className="hidden md:w-80 md:inline-block">
              <Sidebar />
            </div>
            
            {/* content-area */}
            <div className="flex-1">
              <div className="pb-2.5 rounded-md block sm:flex items-center justify-between">
                <div>
                <h2 className="font-semibold text-2xl text-gray-700 mb-1 font-['sans-serif']">Products for <span className='text-green-700'>{value}</span></h2>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{data?.length}</span> of{' '}
                  <span className="font-medium">{totalData?.total}</span> results
                </p>
                </div>
                <div className="flex items-center gap-2 mt-2 sm:mt-0">
                  <select value={sortOption} onChange={handleSort} class="pl-4 pr-10 py-2.5 border border-slate-300 rounded text-sm focus:outline-none focus:border-gray-300">
                    <option hidden="" value="">Select sorting</option>
                    <option value="name-asc">By name A-Z</option>
                    <option value="name-desc">By name Z-A</option>
                    <option value="price-asc">From lowest to highest</option>
                    <option value="price-desc">From highest to lowest</option>
                  </select>
                </div>
              </div>

              <div ref={animationParent} className="mt-4 flex flex-wrap items-center gap-7 justify-center 2xl:justify-between">
                <ProductCard products={data} cartData={cartData.cartItems} favoriteData={favoriteData.favoriteItems} />
              </div>
              <Pagination setPage={setPage} data={data} totalData={totalData} />
            </div>
          </div>
        </section>
  )
}

export default Categories;
