import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Autoplay, EffectCoverflow, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { app_url } from '../../../features/api';
import { addToCart } from '../../../features/cartSlice';
import { addToFavorite } from '../../../features/favoriteSlice';
import SkeletonCard from '../../Shared/CommonCard/SkeletonCard';

const TopProduct = ({sellingProducts}) => {
    const cartData = useSelector((state) => state.cart);
    const favoriteData = useSelector((state) => state.favorite);

    const dispatch = useDispatch();
    const [addedProducts, setAddedProducts] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [count, setCount] = useState(1);

  const handleAddToCart = (data) => {
    const value = {
      data,
      attributes,
      count
    }
    dispatch(addToCart(value));
    setAddedProducts([...addedProducts, data.id]);
  };

  const handleAddToFavorite = (data) => {
    dispatch(addToFavorite(data));
  };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const timer = setTimeout(()=>{
        setLoading(false)
      }, 500)
    
      return () => clearTimeout(timer);

    }, []);

    const decreaseByPercentage = (number, percentage) => {
      const decreaseAmount = (percentage / 100) * number;
      const result = number - decreaseAmount;
      return result.toFixed(2);
    };


    return (
        <section className="bg-gradient-to-r from-[#4b4e6d] to-[#4b4e6d] p-10 2xl:px-20 mb-10">
            <div className="container mx-auto flex flex-wrap items-center justify-center gap-5 md:justify-between">
                <h3 className="xl:max-w-[15ch] font-semibold text-white text-3xl font-['sans-serif']">
                    Top 10 Selling Products On the Week
                </h3>
                
                  {
                    loading ? (
                      Array(4).fill(0).map((sellingProducts, index)=><SkeletonCard key={index} />)
                    ) : (
                      <>
                      {
                        sellingProducts?.length > 0 ? (
                          <>
                          <Swiper
                              grabCursor
                              centeredSlides
                              slidesPerView="auto"
                              spaceBetween={20}
                              modules={[EffectCoverflow, Autoplay, Navigation]}
                              autoplay={{
                                  delay: 4500,
                                  disableOnInteraction: false,
                              }}
                              loop
                              navigation
                              className="xl:max-w-[79%]"
                          >
                              {sellingProducts.map((data) => (
                                  <SwiperSlide className="w-60 bg-white rounded-md" key={data.id}>
                                      <div>
                                          <img
                                              className="rounded-md w-60 h-56"
                                              src={`${app_url}/storage/${data.thumbnail}`}
                                              alt={data.name}
                                          />
                                      </div>
                                      <div className="p-4 flex flex-col gap-1.5">
                                        <Link className="inline truncate" to={`/product-view/${data.slug}`}>{data.name}</Link>
                                        <div className="flex justify-between">
                                          <h5 className="font-normal text-sm text-gray-600">
                                          {
                                            data.discount ? 
                                            <>{data.discount_type == 'percentage' ? <>{decreaseByPercentage(data.price, data.discount)} BDT</> : <>{data.price - data.discount} BDT </>}</> :
                                            <>{data.price} BDT</> 
                                          }
                                          </h5>
                                            {
                                              data.discount ? (
                                                <>
                                                <p className="text-xs line-through bg-orange-400 rounded flex items-center px-1"> 
                                                  {data.discount} {data.discount_type == 'percentage' ? '%' : 'TK'}
                                                </p>
                                                </>
                                              ) : ''
                                            }
                                            
                                        </div>
                                        {/* <div className="action flex gap-3">
                                          {cartData?.cartItems?.map(item => item.id).includes(data.id) ? (
                                          <button
                                              type="button"
                                              disabled
                                              className="text-xs flex-1 border border-gray-400 rounded-md py-1 bg-slate-200  transition-all duration-500"
                                          >
                                              Already Added
                                          </button>
                                        ) : (
                                          <button
                                              type="button"
                                              className="text-xs flex-1 border border-gray-400 rounded-md py-1 hover:bg-green-700 hover:text-white transition-all duration-500"
                                              onClick={() => handleAddToCart(data)}
                                          >
                                              Add to cart
                                          </button>
                                        )}

                                          {favoriteData?.favoriteItems?.map(item => item.id).includes(data.id) ? (
                                            <button
                                              type="button"
                                              className="text-lg border-none rounded-md py-1 px-1 text-red-600"
                                              onClick={() => handleAddToFavorite(data)}
                                            >
                                              <FavoriteIcon />
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="text-lg border-none rounded-md py-1 px-1 text-gray-500 hover:text-red-600"
                                              onClick={() => handleAddToFavorite(data)}
                                            >
                                              <FavoriteBorderIcon />
                                          </button>
                                          )}      
                                        </div> */}
                                        <div className="action flex gap-3">
                                          {
                                            data?.product_attributes?.length > 0 ? <>
                                            {cartData?.cartItems?.map(item => item.id).includes(data.id) ? (
                                              <button
                                                  type="button"
                                                  disabled
                                                  className="text-xs flex-1 border border-gray-400 rounded-md py-1 bg-slate-200  transition-all duration-500"
                                              >
                                                  Already Added
                                              </button>
                                            ) : (
                                              <>
                                              <Link 
                                              to={`/product-view/${data.slug}`}
                                              className="text-xs flex-1 border border-gray-400 rounded-md py-1 bg-green-100 hover:bg-green-700 hover:text-white transition-all duration-500 flex items-center justify-center"
                                              >View Details</Link>
                                              </>
                                            )}
                                              
                                            </> : <>
                                            {cartData?.cartItems?.map(item => item?.data?.id).includes(data?.id) ? (
                                              <button
                                                  type="button"
                                                  disabled
                                                  className="text-xs flex-1 border border-gray-400 rounded-md py-1 bg-slate-200  transition-all duration-500"
                                              >
                                                  Already Added
                                              </button>
                                            ) : (
                                              <>
                                              <button
                                                  type="button"
                                                  className="text-xs flex-1 border border-gray-400 rounded-md py-1 bg-green-700 hover:bg-green-600 text-white transition-all duration-500"
                                                  onClick={() => handleAddToCart(data)}
                                              >
                                                  Add to cart
                                              </button>
                                              </>
                                            )}
                                            </>
                                          }
                                          {favoriteData?.favoriteItems?.map(item => item.id).includes(data.id) ? (
                                            <button
                                              type="button"
                                              className="text-lg border-none rounded-md py-1 px-1 text-red-600"
                                              onClick={() => handleAddToFavorite(data)}
                                            >
                                              <FavoriteIcon />
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="text-lg border-none rounded-md py-1 px-1 text-gray-500 hover:text-red-600"
                                              onClick={() => handleAddToFavorite(data)}
                                            >
                                              <FavoriteBorderIcon />
                                          </button>
                                          )}
                                      </div>
                                      </div>
                                  </SwiperSlide>
                              ))}
                          </Swiper>
                          </>
                        ) : (
                          <div className='h-48 flex items-center'>
                            <p className='text-center text-red-500 font-medium underline font-2xl'>Item not found!</p>
                          </div>
                        )
                      }
                      </>
                    )
                  }
            </div>
        </section>
    );
};

export default TopProduct;
