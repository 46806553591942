import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { TECollapse } from "tw-elements-react";
import { setNHeaders, url } from '../../features/api';
import { clearCart } from "../../features/cartSlice";
import promo from '../../images/Cart/promo.svg';

const CheckOutCard = ({process, formData}) => {
  const auth = useSelector((state) => state.auth);
  const cartData = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState("");
  const [couponData, setCouponData] = useState({});
  const [couponDiscount, setCouponDiscount] = useState(null);
  const [activeElement, setActiveElement] = useState("");
  const [grandTotal, setAGrandTotal] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setAGrandTotal(cartData.cartTotalAmount)
  }, [cartData]);

  // const [shouldReload, setShouldReload] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShouldReload(true);
  //   }, 500000000);
  //   return () => clearTimeout(timer);
  // }, []);

  // useEffect(() => {
  //   if (shouldReload) {
  //     window.location.reload();
  //     setShouldReload(false);
  //   }
  // }, [shouldReload]);

  
  const handleClick = (value) => {
    if (value === activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(value);
    }
  };
  
  // Apply Coupon
  const handleApplyCoupon = () => {
    const payload = {
      coupon: coupon,
      amount : cartData?.cartTotalAmount
    }
    axios.post(`${url}/apply-coupon`, payload)
    .then(response => {
      setCouponData(response?.data)
      setCoupon("")
      if (response?.data?.discount_type === 'percentage') {
        const discountedPrice = cartData?.cartTotalAmount * (1 - response?.data?.discount / 100);
        const final = cartData?.cartTotalAmount - discountedPrice;
        setCouponDiscount(final)
      }else{
        setCouponDiscount(response?.data?.discount)
      }
    })
    .catch(error => {
      if (error?.response?.data?.message?.coupon) {
        // message
        toast.error(error?.response?.data?.message?.coupon[0], {
          position: "top-right",
        });
      }
      toast.error(error?.response?.data?.message, {
        position: "top-right",
      });
    })
  }
  
  const decreaseByPercentage = (number, percentage) => {
    const decreaseAmount = (percentage / 100) * number;
    const result = number - decreaseAmount;
    return result.toFixed(2);
  };
  
  // Calculate total price
  // const calculateTotalPrice = (items) => {
    //   return items.reduce((total, item) => {
      //     let discountedPrice = parseFloat(item.price);
      //     const discountAmount = item.discount_type === 'flat' ? item.discount : discountedPrice * (item.discount / 100);
      //     discountedPrice -= discountAmount;
      //     const totalPriceForItem = discountedPrice * item.cartQuantity;
      //     return total + totalPriceForItem;
      //   }, 0);
      // };
      // const subTotalPrice = calculateTotalPrice(cartData?.cartItems);
      
      // Calculate total tax
      const calculateTotalTax = (items) => {
        return items?.reduce((total, item) => {
          // Extracting relevant properties from item.data
          const { price, discount, discount_type, tax } = item?.data;
      
          let discountedPrice = parseFloat(price);
          const discountAmount = discount_type === 'flat' ? discount : discountedPrice * (discount / 100);
          discountedPrice -= discountAmount;
      
          const totalPriceForItem = discountedPrice * item?.cartQuantity;
          const taxAmount = totalPriceForItem * (tax / 100);
          
          return total + taxAmount;
        }, 0);
      };

      const totalTax = calculateTotalTax(cartData?.cartItems);
  
  // Submit Order
  const handleSubmitOrder = () => {
    const payload = {
      shipping: process?.sAddress,
      address: process?.dAddress,
      paymentMethod: process?.payment,
      cart: cartData,
      tax: totalTax,
    }
    axios.post(`${url}/order-store`, payload, setNHeaders())
    .then(response => {
      // console.log('order res 21:', response?.data)
      if (response?.data) {
        toast.success('Order Successfully Confirmed!', {
          position: "bottom-right",
        });
        dispatch(clearCart());
        navigate("/customer/orders");
      }else{
        toast.error('Something went wrong!', {
          position: "bottom-right",
        });
      }

      // else if (response?.data?.method === 'ssl'){
      //   const storeId = process.env.SSL_STORE_ID;
      //   const storePass = process.env.SSL_STORE_PASS;
        
      //   console.log('storeId', process.env.REACT_APP_STORE_ID);
      //   // console.log('ssl')
      // }
    })
    .catch(error => {
      console.log('error:', error)
    })
  }

  // Submit Order Direct
  const handleSubmitOrderDirect = async () => {
    const payload = {
      shipping: process?.sAddress,
      address: process?.sAddress,
      paymentMethod: process?.payment,
      cart: cartData,
      discount: couponDiscount,
      tax: totalTax,
      name: formData?.name,
      phone: formData?.contact,
      userAddress: formData?.address,
    }

    axios.post(`${url}/order-store-customer`, payload)
    .then(response => {
      if (response?.data) {
        toast.success('Order Successfully Confirmed!', {
          position: "bottom-right",
        });
        dispatch(clearCart());
        navigate("/cart");
      }else{
        toast.error('Something went wrong!', {
          position: "bottom-right",
        });
      }
    })
    .catch(error => {
      console.log('error:', error?.response?.data?.message)
      if(error?.response?.data?.message.phone){
        toast.error(error?.response?.data?.message?.phone[0], {
              position: "bottom-right",
        });
      }
    })
  }

  
  return (
    <>
    <div className="w-full xl:w-1/3 border border-[rgb(212_212_212)] p-5 rounded-md">
        <h3 className="font-medium text-slate-600">My Orders</h3>
        {
          cartData?.cartItems?.length > 0 ? 
          <>
          {
            cartData?.cartItems?.map((item, i) => (
              <div key={i} className="flex items-center gap-3 justify-between py-5">
                <p className="flex font-normal">
                  {item?.cartQuantity} 
                  <span className='mx-1'>X</span> 
                  {
                    item?.data?.discount ? 
                    <>{item?.data?.discount_type == 'percentage' ? 
                      <>{decreaseByPercentage(item?.data?.price, item?.data?.discount)}</> : 
                      <>{item?.data?.price - item?.data?.discount}</>
                    }</> :
                    <>{item?.data?.price}</>
                  }
                </p>

                <small>{item?.data?.name}</small>
                <p className="font-semibold">
                  {/* {(cartData?.cartTotalAmount).toFixed(2)} */}
                  {
                    item?.data?.discount ? 
                    <>{item?.data?.discount_type == 'percentage' ? 
                      <>{(item?.cartQuantity * (decreaseByPercentage(item?.data?.price, item?.data?.discount))).toFixed(2)}</> : 
                      <>{(item?.cartQuantity * (item?.data?.price - item?.data?.discount)).toFixed(2)}</>
                    }</> :
                    <>{(item?.cartQuantity * item?.data?.price).toFixed(2)}</>
                  }
                  {
                    item?.data?.tax > 0 && <span className="font-normal text-xs">+ {item?.data?.tax}% Tax</span>
                  }
                </p>
              </div>
            ))
          }
          
          </> : 
          <>
          <div className="py-8">
            <p className="text-center underline">Cart is empty!</p>
          </div>
          </>
        }
        
        
        <div className="py-5 border-y border-[rgb(212_212_212)] flex flex-col gap-2">
            <div className="flex justify-between items-center">
                <p className="text-[rgb(141_141_141)] text-sm">Subtotal</p>
                <p className="font-semibold">
                {cartData?.cartItems?.length > 0 ? <>{(cartData?.cartTotalAmount).toFixed(2)}</> : <> 0 </>}
                </p>
            </div>
            <div className="flex justify-between items-center">
                <p className="text-[rgb(141_141_141)] text-sm">Shipping</p>
                <p className="font-semibold">{ cartData?.cartItems?.length > 0 ? process?.sAddress ? process?.sAddress?.cost : 0 : 0}</p>
            </div>
            <div className="flex justify-between items-center">
                <p className="text-[rgb(141_141_141)] text-sm">Tax</p>
                <p className="font-semibold">{totalTax.toFixed(2)}</p>
            </div>
            {
              couponData?.discount &&
              <div className="flex justify-between items-center">
                <p className="text-[rgb(141_141_141)] text-sm">Discount</p>
                <p className="font-semibold">
                  {
                    couponData?.discount_type === "flat" ?
                    couponDiscount :
                    couponDiscount.toFixed(2)
                  }
                </p>
              </div>
            }
        </div>

        {cartData?.cartItems?.length > 0 ? <>
          <div className="py-0 border-0 flex justify-between items-center gap-2">
            <div id="accordionExample" className='w-full'>
                <button
                  className={`${
                    activeElement === "element1" &&
                    `text-primary`
                  } group relative flex w-full items-center border-0 px-0 pb-3 pt-2 text-left text-base text-[#006B2E] transition hover:z-[2] focus:z-[3] focus:outline-none `}
                  type="button"
                  onClick={() => handleClick("element1")}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <img src={promo} alt="promo" className='mr-1' />
                  Apply Coupon Code
                  <span
                    className={`${
                      activeElement === "element1"
                        ? `rotate-[-180deg] -mr-1`
                        : `rotate-0 fill-[#006B2E]`
                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </button>
                <TECollapse
                  show={activeElement === "element1"}
                  className="!mt-0 !rounded-b-none !shadow-none border-0"
                >
                  <div className="px-0 flex gap-2">
                    <input
                      type="text"
                      name="name"
                      placeholder='Enter Coupon'
                      className="border border-[rgb(212_212_212)] w-full rounded-md h-8 px-2"
                      value={coupon}
                      onChange={e=>setCoupon(e.target.value)}
                    />
                    {
                      coupon?.length > 3 ?
                      <button onClick={()=>handleApplyCoupon()} className="bg-[#006B2E] px-2 rounded text-slate-100">Apply</button>
                      :
                      <button disabled className="bg-[#bcd0c5] px-2 rounded text-slate-100">Apply</button>
                    }
                  </div>
                </TECollapse>
            </div>
          </div>
        </> : ''}

        
        <div className="py-5 flex justify-between items-center gap-2">
            <p className="text-[rgb(141_141_141)] text-sm">Grand Total</p>
            <p className="font-semibold text-sm">
            {cartData?.cartItems?.length > 0 ? 
            <>
            {
              process?.sAddress ? 
              ((cartData?.cartTotalAmount + process?.sAddress?.cost + totalTax) - couponDiscount).toFixed(2) : 
              ((cartData?.cartTotalAmount + totalTax) - couponDiscount).toFixed(2)
            } BDT
            </> : <>0</>}
            </p>
        </div>
        <div className="grid mt-5">
          {auth.phone_verified_at ?
            <>{
              cartData?.cartItems?.length > 0 ? 
              <>
              {
                process?.sAddress && process?.dAddress && process?.payment ? 
                <>
                <button
                  onClick={()=>handleSubmitOrder()}
                  className="bg-[rgb(73_53_72)] hover:bg-[rgb(86,59,85)] text-white py-2 rounded-md"
                  type="button"
                >
                    Place Order
                </button>
                </> : 
                <>
                <button
                    disabled
                    className="bg-[rgb(120,103,119)] cursor-wait text-white py-2 rounded-md"
                    type="button"
                  >
                    Place Order
                  </button>
                </>
              }
              </> :
              <>
              <button
                disabled
                className="bg-[rgb(120,103,119)] cursor-wait text-white py-2 rounded-md"
                type="button"
              >
                Place Order
              </button>
              </>
            }</>
            :
            // <button
            //   disabled
            //   className="bg-[rgb(120,103,119)] text-white py-2 rounded-md"
            //   type="button"
            // >
            //     Place Order
            // </button>
            <>
            {
                formData?.name && formData?.contact && formData?.address && process?.sAddress && process?.payment ? 
                <>
                <button
                  onClick={()=>handleSubmitOrderDirect()}
                  className="bg-[rgb(73_53_72)] hover:bg-[rgb(86,59,85)] text-white py-2 rounded-md"
                  type="button"
                >
                    Place Order
                </button>
                </> : 
                <>
                <button
                    disabled
                    className="bg-[rgb(120,103,119)] cursor-wait text-white py-2 rounded-md"
                    type="button"
                  >
                    Place Order
                  </button>
                </>
              }
            </>
          }
            
        </div>
    </div>
    </>
  )
}

export default CheckOutCard
