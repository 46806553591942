import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./api";

const initialState = {
  products: [],
  newProducts: [],
  discountProducts: [],
  bestSellingProducts: [],
  categoryProducts: [],
  searchProducts: [],
  productsStatus: null,
  productsError: null,
  newProductsStatus: null,
  newProductsError: null,
  discountProductsStatus: null,
  discountProductsError: null,
  bestSellingProductsStatus: null,
  bestSellingProductsError: null,
  categoryProductsStatus: null,
  categoryProductsError: null,
  searchProductsStatus: null,
  searchProductsError: null,
};

// all products
export const getProducts = createAsyncThunk('products/getProducts', async () => {
  try {
    const response = await axios.get(`${url}/products`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

// new arrival Products
export const getNewArrivalProducts = createAsyncThunk(
  'products/getNewArrivalProducts',
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${url}/products/new_arrival?page=${page}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// discount Products
export const getDiscountProducts = createAsyncThunk(
  "products/getDiscountProducts", 
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${url}/products/discount?page=${page}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// best_selling Products
export const getBestSellingProducts = createAsyncThunk(
  "products/getBestSellingProducts",
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${url}/products/best_selling?page=${page}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


// get category wise Products
export const getCategoryProducts = createAsyncThunk(
  "products/getCategoryProducts",
  async (data) => {
    try {
      const response = await axios.get(`${url}/products/category/${data?.category}?page=${data?.page}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }
);

// get search wise Products
export const getSearchProducts = createAsyncThunk(
  "products/getSearchProducts",
  async (searchVal) => {
    try {
      const response = await axios.get(`${url}/products/search/${searchVal}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }
);


const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get all Products
    builder.addCase(getProducts.pending, (state, action) => {
      state.productsStatus= "pending";
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.productsStatus = 'success';
      state.products = action.payload;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.productsStatus = 'rejected';
      state.productsError = action.error.message;
    });

    //getNewArrivalProducts
    builder.addCase(getNewArrivalProducts.pending, (state, action) => {
      state.newProductsStatus= "pending";
    });
    builder.addCase(getNewArrivalProducts.fulfilled, (state, action) => {
      state.newProductsStatus = 'success';
      state.newProducts = action.payload;
    });
    builder.addCase(getNewArrivalProducts.rejected, (state, action) => {
      state.newProductsStatus = 'rejected';
      state.newProductsError = action.error.message;
    });

    //getDiscountProducts
    builder.addCase(getDiscountProducts.pending, (state, action) => {
      state.discountProductsStatus= "pending";
    });
    builder.addCase(getDiscountProducts.fulfilled, (state, action) => {
      state.discountProductsStatus = 'success';
      state.discountProducts = action.payload;
    });
    builder.addCase(getDiscountProducts.rejected, (state, action) => {
      state.discountProductsStatus = 'rejected';
      state.discountProductsError = action.error.message;
    });

    //getBestSellingProducts
    builder.addCase(getBestSellingProducts.pending, (state, action) => {
      state.bestSellingProductsStatus = "pending";
    });
    builder.addCase(getBestSellingProducts.fulfilled, (state, action) => {
      state.bestSellingProductsStatus = 'success';
      state.bestSellingProducts = action.payload;
    });
    builder.addCase(getBestSellingProducts.rejected, (state, action) => {
      state.bestSellingProductsStatus = 'rejected';
      state.bestSellingProductsError = action.error.message;
    });

    //getCategoryProducts
    builder.addCase(getCategoryProducts.pending, (state, action) => {
      state.categoryProductsStatus = "pending";
    });
    builder.addCase(getCategoryProducts.fulfilled, (state, action) => {
      state.categoryProductsStatus = 'success';
      state.categoryProducts = action.payload;
    });
    builder.addCase(getCategoryProducts.rejected, (state, action) => {
      state.categoryProductsStatus = 'rejected';
      state.categoryProductsError = action.error.message;
    });

    //getSearchProducts
    builder.addCase(getSearchProducts.pending, (state, action) => {
      state.searchProductsStatus = "pending";
    });
    builder.addCase(getSearchProducts.fulfilled, (state, action) => {
      state.searchProductsStatus = 'success';
      state.searchProducts = action.payload;
    });
    builder.addCase(getSearchProducts.rejected, (state, action) => {
      state.searchProductsStatus = 'rejected';
      state.searchProductsError = action.error.message;
    });
  },
});

export const { productPage } =
productsSlice.actions;

export default productsSlice.reducer;
